<template>
  <div class="introduce">
    <div class="leftBody">
      <a-tree
        class="tree"
        :tree-data="treeList"
        :expandedKeys.sync="expandedKeys"
        @select="onSelect"
      >
      </a-tree>
    </div>
    <div class="rightBody">
      <!-- <quill-editor v-model="value" class="wenben"></quill-editor> -->
      <div class="wenben ql-snow ql-editor" v-html="value"></div>
    </div>
  </div>
</template>

<script>
import { getCPList, getCPDetail } from "../services/user";
export default {
  name: "Home",
  data() {
    return {
      value: "",
      z_id: "",
      treeData: [],
      treeList: [],
      expandedKeys: [],
    };
  },
  components: {},
  created() {
    this.HuoQuChanPin(this.$qs.stringify(this.CanShuJiHeList()));
  },
  mounted() {},
  watch: {},
  methods: {
    CanShuJiHeList() {
      let obj = {
        CanShuJi: JSON.stringify({
          YongHuCanShu: { QianMingId: "" },
          QiTaCanShu: { Cate: "技术档案" },
        }),
      };
      return obj;
    },
    HuoQuChanPin(obj) {
      getCPList(obj).then((res) => {
        if (res.data.BianHao == "0000") {
          this.treeData = res.data.JieGuo.Table;
          this.treeData.forEach((item) => {
            if (
              item.IsFJ == "是" &&
              (item.ParentBm == null || item.ParentBm.length == 0)
            ) {
              let treeNode = { title: item.Name, key: item.Bm, children: [] };
              treeNode.children = this.loadTreeData(item.Bm);
              this.treeList.push(treeNode);
            }
          });
        } else {
          this.$message.error(res.data.XinXi);
        }
      });
    },
    loadTreeData(ParentBm) {
      let children = [];
      this.treeData.forEach((tree) => {
        if (tree.ParentBm == ParentBm) {
          let treeZJ = { title: tree.Name, key: tree.Bm, children: [] };
          treeZJ.children = this.loadTreeData(tree.Bm);
          children.push(treeZJ);
        }
      });
      return children;
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
      if (selectedKeys[0]) {
        this.HuoQucpDetail(
          this.$qs.stringify(this.CanShuJiHe(selectedKeys[0]))
        );
      }
    },
    HuoQucpDetail(obj) {
      getCPDetail(obj).then((res) => {
        if (res.data.BianHao == "0000") {
          let infoData = res.data.JieGuo[0];

          this.z_id = infoData.Id;
          this.value = infoData.Contents;
        } else {
          this.z_id = "";
          this.value = "";
          this.$error({
            title: "错误，请联系管理员",
            content: res.data.XinXi,
          });
        }
      });
    },
    CanShuJiHe(bm) {
      let obj = {
        CanShuJi: JSON.stringify({
          YongHuCanShu: { QianMingId: "" },
          QiTaCanShu: { Bm: bm },
        }),
      };
      return obj;
    },
  },
};
</script>
<style lang="less" scoped>
.introduce {
  display: flex;
  width: 100%;
  height: calc(100% - 112px);
}
.leftBody {
  width: 16%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid #f3f8f8;
  background: #fffdfd;
}
.rightBody {
  width: 84%;
  height: 100%;
  overflow: auto;
  .wenben {
    width: 70%;
    margin: 8px auto 0;
    overflow: visible;
    /deep/img {
      max-width: 100%;
    }
    // /deep/.ql-align-center {
    //   text-align: center;
    // }
    // /deep/.ql-align-right {
    //   text-align: right;
    // }
    /deep/.ql-syntax {
      background-color: #23241f;
      color: #f8f8f2;
      overflow: visible;
      border-radius: 3px;
    }
  }
}
.tree {
  margin: 20px 7px;
}
/deep/ .ant-tree-title {
  font-family: "幼圆";
  font-weight: bolder;
}
.tree {
  /deep/ li {
    overflow: hidden;
    .ant-tree-node-content-wrapper {
      font-size: 16px;
    }
    span {
      .anticon {
        font-size: 16px !important;
      }
    }
  }
}
</style>
